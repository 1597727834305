import { Table } from '@stuller/stullercom/ui'
import { type ReactElement, type ReactNode } from 'react'

interface ReferenceProps {
  tableStyle?: string[] | null
  children: ReactNode
}

/**
 * Component for rendering content with the "table" type in contentstack's rich text editor
 */
function ContentstackJsonRteTable ({ tableStyle, children }: ReferenceProps): ReactElement | null {
  const tableStyles: Record<string, boolean | string | null> = {
    bordered: tableStyle?.includes('bordered') === true,
    borderless: tableStyle?.includes('borderless') === true,
    striped: tableStyle?.includes('striped rows') === true ? true : tableStyle?.includes('striped columns') === true ? 'columns' : false,
    hover: tableStyle?.includes('hover') === true,
    size: tableStyle?.includes('small') === true ? 'sm' : null
  }

  return (
    <Table className='overflow-hidden' rounded responsive {...tableStyles}>
      {children}
    </Table>
  )
}

export { ContentstackJsonRteTable }
