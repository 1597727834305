import clsx from 'clsx'
import { type CSSProperties, type ReactElement } from 'react'

interface ContentstackJsonRteTypographyProps {
  /**
   * Is text bold
   */
  bold?: boolean
  /**
   * Is text italic
   */
  italic?: boolean
  /**
   * Is text striked through
   */
  strikethrough?: boolean
  /**
   * Is text underlined
   */
  underline?: boolean
  /**
   * Is text superscript
   */
  superscript?: boolean
  /**
   * Is text subscript
   */
  subscript?: boolean
  /**
   * Is inline code
   */
  inlineCode?: boolean
  /**
   * object of styles from the JSON RTE
   */
  style?: CSSProperties
  /**
   * Text content entered in the JSON RTE
   */
  text?: string
  /**
   * Id manually added in contentstack's JSON RTE
   */
  id?: string
  /**
   * Class names manually added in contentstack's RTE
   */
  className?: string

}

/**
 * Component for rendering content with various typography types in contentstack's rich text editor
 */
function ContentstackJsonRteTypography ({
  superscript,
  subscript,
  inlineCode,
  underline,
  strikethrough,
  italic,
  bold,
  style,
  className,
  text,
  id
}: ContentstackJsonRteTypographyProps): ReactElement {
  const Typography = superscript != null ? 'sup' : subscript != null ? 'sub' : inlineCode != null ? 'code' : 'span'
  const typographyClasses = clsx(
    className,
    bold != null && 'fw-bold',
    italic != null && 'fst-italic',
    underline != null && 'text-decoration-underline',
    strikethrough != null && 'text-decoration-line-through'
  )

  return (
    <Typography
      id={id}
      className={typographyClasses}
      style={{ ...style }}
    >
      {text}
    </Typography>
  )
}

export { ContentstackJsonRteTypography }
