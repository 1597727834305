import { type ReactNode } from 'react'
import { type JsonNode } from './types'
import { ContentstackJsonRteChildren } from './ContentStackJsonRteChildren'

interface ContentstackJsonRteProps {
  /**
   * JSON data from Contentstack
   */
  json: any | { children: JsonNode[] }
  /**
   * Exclude outer tags and only return children
   */
  excludeOuterTags?: boolean
  /**
   * Name of table style selected in contentstack
   */
  tableStyle?: Array<string | null> | null
  /**
   * Is a table
   */
  table?: boolean
}

/**
 * Helper function to not render any parent elements when JSON from Contentstack's JSON RTE is empty
 */
function isRteEmpty (json?: any | { children: JsonNode[] }): boolean {
  if (json == null) {
    return true
  }

  const typedJson = json as { children: JsonNode[] }
  const { children } = typedJson

  return children?.length === 1 &&
    children[0].type === 'p' &&
    children[0]?.children?.length === 1 &&
    children[0].children[0].text != null &&
    children[0].children[0].text.trim().length === 0
}

/**
 * Component for rendering data from Contentstack's JSON RTE as JSX
 */
function ContentstackJsonRte ({ json, excludeOuterTags, tableStyle, table = false }: ContentstackJsonRteProps): ReactNode | null {
  const emptyJsonRte = isRteEmpty(json)
  if (emptyJsonRte) {
    return null
  }

  const tableStyleFiltered = tableStyle?.filter(style => style != null) as string[] | null

  return <ContentstackJsonRteChildren table={table} tableStyle={tableStyleFiltered} excludeOuterTags={excludeOuterTags}>{json.children}</ContentstackJsonRteChildren>
}

export {
  ContentstackJsonRte,
  isRteEmpty
}
