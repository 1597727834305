import Link from 'next/link'
import { type ReactElement } from 'react'

interface ReferenceProps {
  attrs?: Record<string, any>
}

/**
 * Component for rendering content with the "reference" type in contentstack's rich text editor
 * these come from the asset uploader
 */
function Reference ({ attrs }: ReferenceProps): ReactElement | null {
  if (attrs?.['asset-type'] == null) {
    return null
  }
  const {
    'asset-type': assetType,
    'asset-alt': alt = assetType,
    'asset-link': assetLink,
    link,
    'max-width': maxWidth = '100%',
    target = '_blank'
  } = attrs

  switch (assetType) {
    case 'image/svg+xml':
    case 'image/png':
    case 'image/jpeg':
      return (
        link != null
          ? <Link href={link} target={target}><img src={assetLink} alt={alt} className='img-fluid' /></Link>
          : <img src={assetLink} alt={alt} className='img-fluid' />
      )
    case 'video/mp4':
      if (assetLink == null) {
        return null
      }

      return (
        <video className='w-100' style={{ maxWidth }} controls>
          <source src={assetLink} />
          {alt}
        </video>
      )
    // there are other asset types that can be uploaded, but they would not be used
    default:
      return null
  }
}

export { Reference }
