import { type ReactNode } from 'react'
import { RenderJson } from './RenderJson'
import { type JsonNode } from './types'

interface ContentstackJsonRteChildrenProps {
  /**
   * Array of JsonNode from the json object from contentstack's rich text editor
   */
  children: JsonNode[]
  /**
   * Exclude outer tags and only return children
   */
  excludeOuterTags?: boolean
  /**
   * Is a table
   */
  table?: boolean
  /**
   * Name of table style selected in contentstack
   */
  tableStyle?: string[] | null
}

/**
 * Maps the children array that is part of the JSON object from contentstack's rich text editor
 */
function ContentstackJsonRteChildren ({ children, excludeOuterTags, tableStyle, table = false }: ContentstackJsonRteChildrenProps): ReactNode {
  return children.map((data, i) => <RenderJson key={data?.uid ?? i} excludeOuterTags={excludeOuterTags} tableStyle={tableStyle} {...data} table={table} />)
}

export { ContentstackJsonRteChildren }
